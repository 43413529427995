import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import Message from './Message';
import UserInput from './UserInput';

const ChatWindow = ({ darkMode }) => {
  const [messages, setMessages] = useState([]);
  const [pstatus, setProfilestatus] = useState(''); // User profile status
  const chatRef = useRef(null);
  const socketRef = useRef(null);
  const messageQueueRef = useRef([]);
  const LOCAL_STORAGE_KEY = "currentStepIndex";
  const DATA_STORAGE_KEY = "formData";




  // Local states for step, type, and data
  const [step, setStep] = useState(localStorage.getItem('step'));
  const [stop, setStop] = useState(localStorage.getItem('stop_here'));
  const [send, setSend] = useState(localStorage.getItem('send'));


  const [res, setResponse] = useState(JSON.parse(localStorage.getItem('response')) || []);
  const [type, setType] = useState(localStorage.getItem('type'));
  const [data, setData] = useState(JSON.parse(localStorage.getItem('data')) || []);

  // Refs to store the latest step, type, and data values
  const stepRef = useRef('');
  const typeRef = useRef('');
  const dataRef = useRef([]);
  const responseRef = useRef([]);
  const stopRef = useRef([]);
  const sendRef = useRef([]);


  const userId = localStorage.getItem('userId');

  const steps = ["fullname", "matric"];
  const validationConfig = data;

  function extractDataWithTitles(obj, result = {}) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            // Skip 'id'
            if (key === "id") {
                continue;
            }

            // Handle 'others' separately
            if (key === "others") {
                // Include only 'amount' if it exists in 'others'
                if (value.hasOwnProperty("amount")) {
                    result["amount"] = `${parseFloat(value["amount"]).toFixed(2)}`;
                }
                continue;
            }

            // Recurse into nested objects or arrays
            if (typeof value === "object" && value !== null) {
                const nestedResult = {};
                extractDataWithTitles(value, nestedResult);

                // If a nested object has a title, associate it with its parent key
                if (nestedResult.title) {
                    result[key] = nestedResult.title;
                    delete nestedResult.title;
                }

                // Merge remaining nested results
                Object.assign(result, nestedResult);
            } else {
                // Add key-value pairs dynamically
                result[key] = value;
            }
        }
    }
    return result;
}

  const processStep = async (input) => {
    let flowStepIndex = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY)) || 0;
    const flowStep = steps[flowStepIndex]; // Get the current step
    const validationRules = validationConfig[flowStep]?.rules;

    if (!validationRules) {
      //console.error(`No validation rules defined for step: ${flowStep}`);
      return;
    }

    // Validate input based on the rules for the current step
    const isValid = validateInput(input, validationRules);
    if (!isValid) {
      addMessage(`Invalid ${flowStep} entry. Please follow the rules and try again.`, "bot");
      return; // Stop processing for invalid input
    }

    // Save valid input
    saveStepData("userId", userId);
    saveStepData(flowStep, input);

    // Move to the next step
    flowStepIndex++;
    localStorage.setItem(LOCAL_STORAGE_KEY, flowStepIndex); // Ensure updated flowStepIndex is saved

    if (flowStepIndex < steps.length) {
      // Get instructions for the next step
      const nextStep = steps[flowStepIndex];
      const instructions = validationConfig[nextStep].instructions;
      //console.log("mystep",nextStep)

      addMessage(instructions, "bot");
    } else {
      const payload = getAllStepData();
     
      if (stop === "yes") {
        const extractedData = extractDataWithTitles(payload);
       // console.log("Final Payload:", payload);
        const rt = `
          Confirm the information before proceed<br/><br/>
          <b>Payment Name:</b> ${extractedData?.package}<br/><br/>
          <b>Name:</b> ${extractedData?.fullname}<br/><br/>
          <b>Matric No:</b> ${extractedData?.matric}<br/><br/>
          <b>Level:</b> ${extractedData?.level}<br/><br/>
          <b>Amount:</b> ₦${Number(extractedData?.amount).toFixed(2)}<br/><br/>
          Type YES to continue | NO cancel
        `;
        addMessage(rt, "bot");
        setStep("confirmation")
        return;
      }
    }
  };
  

  const validateInput = (input, rules) => {
    for (const rule of rules) {
      switch (rule.type) {
        case "min_length":
          if (input.length < rule.value) return false;
          break;
  
        case "numeric":
          if (isNaN(input)) return false;
          break;

        
        case "listdata":
            if (isNaN(input)) return false;
            break;
        
        
  
        case "regex":
          // Convert rule.value to a RegExp object if it's a string
          let regex;
          if (typeof rule.value === 'string') {
            regex = new RegExp(rule.value);
          } else if (rule.value instanceof RegExp) {
            regex = rule.value;
          } else {
            console.error('Expected rule.value to be a regular expression or string, but got:', rule?.value);
            return false;
          }
  
          if (!regex.test(input)) return false;
          break;
  
        default:
          console.warn(`Unknown validation rule type: ${rule.type}`);
      }
    }
    return true;
  };
  

  const saveStepData = (step, value) => {
    const existingData = JSON.parse(localStorage.getItem(DATA_STORAGE_KEY)) || {};
    existingData[step] = value;
    localStorage.setItem(DATA_STORAGE_KEY, JSON.stringify(existingData));
  };

  const getAllStepData = () => {
    setSend("yea")
    return JSON.parse(localStorage.getItem(DATA_STORAGE_KEY)) || {};
  };
  function isImagekitLink(foundItem) {
    // Check if foundItem is a string and contains "imagekit"
    if (typeof foundItem === "string" && foundItem.includes("imagekit")) {
        try {
            // Validate if it's a URL
            const url = new URL(foundItem);
            return true;
        } catch (e) {
            // If it's not a valid URL, return false
            return false;
        }
    }
    return false;
}



  const processmsg = async (msg) => {
    let foundItem = null;
    let foundarray = null;

    const currentStep = step;
    const currentType = type;
    const currentData = data;

    //console.log(currentData);

    if (currentStep && currentStep.trim() !== "") {
      try {
        if (currentType === "list") {
          const foundId = currentData?.find((item) => item?.id?.toUpperCase() === msg?.toUpperCase());
          foundItem = foundId?.step_id;
          foundarray = foundId;
        } else {
          foundItem = msg;
        }
      } catch (error) {
        console.error("Invalid JSON in data:", error);
      }
    }

    addMessage(msg, "user");

    const keywords = ["cancel", "quit", "stop", "restart"];
    if (keywords.some((keyword) => msg.toLowerCase().includes(keyword))) {
      await cancelProcess();
      return foundItem || msg;
    }
    if (currentStep === "confirmation") {
      if (msg === "NO") {
       await cancelProcess()
        return "cancel"; // Invalid input; stop processing
      }
      else if(msg === "YES"){
        setSend("yea")
        const payload = getAllStepData();
        addMessage("Please wait.....", "bot");
        await cancelProcess()
       
        
        return  payload;

      }
      else{
        addMessage("Please type yes or no", "bot");
        return;
      }
    }

    if (currentStep === "first_entry") {
      if (!foundItem) {
        addMessage("Invalid selection, please try again", "bot");
        return null; // Invalid input; stop processing
      }
    }

    if (currentStep === "option_entry") {
      if (!foundItem) {
        addMessage("Invalid selection, please try again", "bot");
        return null; // Invalid input; stop processing
      }
      if(res?.save_data ==="yes"){
        saveStepData(res?.name, foundarray);
     // console.log("amfound",foundarray)
      }
    }

    if (currentStep === "text_entry") {
      if (res?._is_flow_step) {
        const tt = await processStep(msg)
        // console.log(tt)
        // foundItem = tt
        return null;
      } else {
        return foundItem || msg;;
      }
    }

    return foundItem || msg;
  };


  const cancelProcess = async () => {

    // Clear React state
    setStep("");
    setType("");
    setData([]);

    // Clear localStorage
    localStorage.removeItem("step");
    localStorage.removeItem("type");
    localStorage.removeItem("data");
    localStorage.removeItem(LOCAL_STORAGE_KEY)

   // console.log("Process canceled and state reset.");
  };
  const processIncomming= (parsedData)=>{
    let msgres;
        // Update state and refs for step, type, and data
        const newStep = parsedData?.step || '';
        const newType = parsedData?.type || '';
        const stop_here = parsedData?.stop_here || '';
        const newData = parsedData?.body?.data || [];
        const response = parsedData?.body?.response || [];
       // console.log(parsedData)
        msgres = parsedData?.body?.text;
        const img = isImagekitLink(msgres)
       // console.log(msgres)
        if(img){
          addMessage(msgres, "receipt");
          cancelProcess()
          return;
        }

        if(newStep === "text_entry" & response?._is_flow_step){
          const firstKey = Object.keys(newData)[0];
          const firstData = newData[firstKey];
          msgres =firstData?.instructions;
          if(firstData?.firstmode === "listdata"){
            msgres = parsedData?.body?.text;
          }
          //console.log("newd",firstData)
        }

        addMessage(msgres, 'bot');

        setStep(newStep);
        setResponse(response);
        setType(newType);
        setData(newData);
        setStop(stop_here);

        // Update refs for latest state values
        stepRef.current = newStep;
        typeRef.current = newType;
        dataRef.current = newData;
        responseRef.current = response;
        stopRef.current = stop_here;
        sendRef.current = false;

        // Update localStorage
        localStorage.setItem('stop_here', stop_here);
        localStorage.setItem('send', false);
        localStorage.setItem('type', newType);
        localStorage.setItem('type', newType);
        localStorage.setItem('data', JSON.stringify(newData));
        localStorage.setItem('response', JSON.stringify(response));
  }

  useEffect(() => {

    socketPush()
  }, []);

  const socketPush = () => {
    // if (socketRef.current) {
    //   return; 
    // }
  
    // Initialize socket connection
    socketRef.current = io.connect('http://chatweb.paysnug.link/', {
      reconnection: true, // Enable auto-reconnection
      reconnectionAttempts: Infinity, // Try to reconnect forever
      reconnectionDelay: 1000, // Start with a 1-second delay
      reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts
    });
  
    // On connection
    socketRef.current.on('connect', () => {
      //console.log('Connected to server');
  
      // Emit join event with userId
      socketRef.current.emit('join', userId);
  
      // Process any queued messages
      while (messageQueueRef.current.length > 0) {
        const message = messageQueueRef.current.shift();
        // sendMessage(message);
      }
    });

    socketRef.current.on('receipt', (newMessage, messageId) => {
      if (newMessage !== '') { // Check if the response is not empty
        addMessage(newMessage, "receipt");
        cancelProcess()
      }
      socketRef.current.emit('ack', messageId); // Send acknowledgment
    });
  
    // Handle new incoming messages
    socketRef.current.on('newResponse', (newMessage, messageId) => {
      if (newMessage !== '') {
        const parsedData = JSON.parse(newMessage);
        processIncomming(parsedData);
      }
  
      // Acknowledge receipt of the message
      socketRef.current.emit('ack', messageId);
    });

    socketRef.current.on('newacct', (newMessage, messageId) => {
      if (newMessage !== '') {
        //const parsedData = JSON.parse(newMessage);
       // console.log("mppp",newMessage)
        setStep('');
        setResponse('');
        setType('');
        setData('');
        setStop('');
        addMessage(newMessage?.message, 'bot');
      }
  
      // Acknowledge receipt of the message
      socketRef.current.emit('ack', messageId);
    });
  
    // Handle disconnection
    socketRef.current.on('disconnect', (reason) => {
     // console.log(`Disconnected from server: ${reason}`);
     // console.log('Attempting to reconnect...');
      if (reason === "io server disconnect") {
        socketRef.current.connect();
      }
      
    });
  
    // Handle reconnection.
    socketRef.current.on('reconnect', (attemptNumber) => {
     // console.log(`Reconnected to server after ${attemptNumber} attempt(s)`);
    });
  
    // Handle reconnection errors
    socketRef.current.on('reconnect_error', (error) => {
     // console.log('Reconnection error:', error);
    });
  
    // Handle permanent connection failure
    socketRef.current.on('reconnect_failed', () => {
      //console.log('Failed to reconnect to server after multiple attempts.');
    });
  };
  
  useEffect(() => {
    const storedMessages = JSON.parse(localStorage.getItem('chatMessages')) || [];
    setMessages(storedMessages);

    chatRef.current.scrollTop = chatRef.current.scrollHeight;

    if (userId) {
      socketPush();
    }

    // Cleanup socket on component unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messages]);

  const addMessage = (text, sender) => {
    const newMessage = { text, sender, timestamp: new Date().toLocaleTimeString() };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const sendMessage = async (userMessage) => {
    const userMessageUppercase = userMessage.toUpperCase();
  const process = await processmsg(userMessageUppercase);


    const name = localStorage.getItem('name');
    if (navigator.onLine) {
      if (!process) {
        return;
      }
      //console.log(send)
      if(send === "yea"){
        socketRef.current.emit('acct',process );
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
      else{

      socketRef.current.emit('message', { message: process, userId: userId, name: name });
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
      setSend(false)
    } else {
     // console.log('No network connection. Message not sent.');
    }
  };

  const handleUserMessage = (userMessage) => {
    const isValidNigerianNumber = (number) => {
      const regex = /^234\d{10}$/; // Validates Nigerian phone numbers
      return regex.test(number);
    };

    if (!userId) {
      addMessage(userMessage, 'user');

      if (isValidNigerianNumber(userMessage)) {
        localStorage.setItem('userId', userMessage); // Save the number as userId
        addMessage('Please enter your first name.', 'bot');
      } else {
        addMessage('Please enter a valid WhatsApp number in the Nigerian format (234XXXXXXXXXX).', 'bot');
      }
      return;
    }

    const checkName = localStorage.getItem('name');
    if (!checkName) {
      if (userMessage?.length >2) {
        localStorage.setItem('name', userMessage);
        setProfilestatus('name_entered');
      } else {

        addMessage('Invalid firstname.', 'bot');
        return;
      }
   
    }

    messageQueueRef.current.push(userMessage);
    sendMessage(userMessage);
  };

  return (
    <div className="chat-window">
      <div className="messages" ref={chatRef}>
        {messages.map((message, index) => (
          <Message
            key={index}
            text={message.text}
            sender={message.sender}
            timestamp={message.timestamp}
            darkMode={darkMode}
          />
        ))}
      </div>
      <br />
      <br />
      <br />
      <div className="user-input-fixed">
        <UserInput addMessage={handleUserMessage} darkMode={darkMode} />
      </div>
    </div>
  );
};

export default ChatWindow;
